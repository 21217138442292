
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexDIZPSP3uKb4u0gbNTNp_45XqeUykHBxZv7C1bMArVLbnIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginAoVmwZJYnu0_45oJ9IuEFzk1PNyg8W17mynjFetG_45YfsQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifylLI3DT5H3NiRb6KzYhNEOsH8zsYhESTEiSWCXM2nQLwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapWXYnssKrh2Tkb5liFDR9fNvKyVhaqyOOHTFigJT_al0Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usw5lAW3Ckcg7EbOo5E1x8RHpL1YS192MbhjhyqpjC2FcMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerzqTUtla5S2a6ckWIk8URK7F3sImDqK8EEG1eCUNMlgEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintssOGkaY0Qf69mPU8zfEsZoNZuA9cVhhXR4BmK968MNrwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usPCE8B_8jX_45zUAUn_F8SqWwOAypkWGWe_45yRroyRzmj8EMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexH9mxezm__45FJm3Fp40SV4A5EZu8BC_45Lbx_GEH8ExUxtoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as index0Lzmr6M_MxWGgrAe4wvYzmmY1eY_fdaBgwHThw54HwoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93vWnv4_45ncW4EBj_23NXeklqscNb4gStT0nOYAyNjw9nkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipseJNX6jo60eFI434HbwmKat6XIg4om9XoJ2Q2tASDIFYMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45usecoOO0YmoAqpy9S7KYwoF3pXErlvPEQckyIXTaA4cOL0Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as index4kD25A10SUhVHRESGf4pxw8XJw3i9FzBv2vxNCcjP20Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyoyro8OSjtZ4_FGYf9XIRFm0nXrysg0mI8f0huZWO0lYMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45password87yJhWjA4n0EBTewRWiGuP4ZwfT68JUhDkprXNdSApsMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexP_7Tz7SA3FpgIgz49s41VcaeFZGeaH9eHkZZNOFJQlwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexgLYBx_9ALvcway7EDiqRa0PAhwZ9XzWzlr6fMhuZGfsMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordso4pJlgYsdnIfEM58FmmVw5OG1_45krsjpkvaxaQ90o6MMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexuRgl68TGIbJJl8Q_45mQPmdu_GELp2vRv_DJRZH_45C5fqkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93bUfpcbLBFCufPheLtjbY8LVukM1EQE_45XrIePceoxliEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesY4NivmZBj8UUhjGFo44zGyXzARoyo7AF5jOX2cX3cB0Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticemp8_45isKqIM1c_GNB2iD9TO3rFx8XqPTl4OZJz_45yZ8TEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexga158MjTpUm_45Y7HysNKmnlukyU8djy0CTU_45837cdGkUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordW4Kdu2sG1sSSokSJBJIjIAEwcLbzVp6pHgSC5fymNLUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backYAD0ZkcbxZzxszHN3swYUGEplxbVcUoc_45FrUSfGUT34Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeEH2AYNckvyfPsmlfY5XeREvOQlR45zn8C_45S8KeCCPdAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexrG8xlRu_45I2w7_ER1pGrXV_ytVkwk0TeG3c3ehBP2slMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93i3H85dutAfpDd4TAMI1SZBOtBZxpYhaFQ93oaW46_DAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youCf3cLDlDuH416yG84B702_YMZqZucA7AOcRJMRnJVxUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as index9LTPBY6_459t0N9Uuuv05FkTc8BadXxbedECdPv6EGpYcMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexJ8TEzBhMqe6BC3xzsH2CD69ZI5rRoiAcW7PGcY4_BG0Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93RoJsf_fw0f9t6HXMiPAuqydI9h18SEVQdQcTCrmCFQ8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexKfbnWZzsAvlRtqxbvxLH5_4564ZKRdhz4_xVp_9rrSslEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexjQbc8gh6E7VXbLDF7Tzvj3cfU4HE35z5OnP74ZakP_45QMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93WuFk4hWldIfr1lgUN_g_CWDwlQQhyXbtAE6o1k4kwSQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackFjEdyAr28Yjqd83cIQhNMbMWoqDKnZ9lqfAnMR5FQlIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimrvtpNw_ChUROeWGW_dIpvpbHsL4Yqnlh_m_45ZVumtlM8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexMrKxtYoXyErFQ49oJzJDs5V6kw2q3lDjjwx_45qzb96lkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youcOnAKPoLvx0bkW2BUduczMZVu4qlAH4TbL1aao0QqLIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorRYAK3rCg6wK0bcBxmw8ysLxkgoNwz6aq_xHEKbfSGOQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexhYRS2C3LNZ_45yHg5udbi4uLnYrhvIthUIln0S6X4qq_45gMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as index5NK4Gjtzhz1xswuukEh7iXZUiRtDKZc3q74cDZpDLzwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorH6PQ0Q_45bYEcDJnSCV3IDU6hAHXNE_45MQQ49aXAZ0Ht2cMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93aWBfbRVK0IeXTw6pJ_459cIAP5nYx1QOxM1m5KPGFOFLwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsgr9KCqDMFaEMSRW33sPaOnLv_ahgYCGN5lWoEEV1qrkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45rates60csxRQSz65TbUqVUh_45BvPQTDQ1xJjNP1WctQ72IXzoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93yRgicbrC27Hv2jxF2FJAzAL08X0Y_m3afMfPeaN_NNEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexvTGosRn41OjowdoIKrPOEzCK8I9etHwzT_0grp2i__45EMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settings8yJYijZ6ehM9va5opDHdnp8pUCE0JH_455oMe66RJ8OgAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexBVFOdcwG7SsHKFAK314ZlfbvgAYAbigLU78ri4NE_u8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat6eGcG_QV_459T7_P1YaamYjwEswGWT6lxmLhP3phNJZXIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleKTKw1F_dLcZoLekg2ft9K5etySThOM3vLkGRq_452_LQoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youMI_vwZ_O5cFaIkMtNNXPKVIYKWzOT_45EMP3lORw29EuQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveSAsjvV6_45O5MuECofDNJ3MrNdotbfEAsEWeOWSvv26t8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93rKJbluG7vJdySiPR_HCUj97iC_45hyE17OEIyb5xTmOpwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addhOQpyQLZNzj0oEtUPnKSqOjctOMPXchY9DEplWWC9fQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexiVIa4RcAj0wqFY1GRL0BoFE6NdKq9TMgUuYwz2Hl0eoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93ob_PZitBFDY3qiZ8GJHpcxmR7EZxfaEd6q5ld8aldoMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackSdCx1ypM1v4zQLZkv4Wlm23yjhaCGGozvEFbU1UIRiQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93IEe1WYhrGkXc12ugITmlNLym3qZjBCv2yagzE4YMURAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youUwcQH6L9CNn5R7MnDK_qnaOU85Pt14smibFQIehhnywMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b_bOyvt_45Hgce8jTIhbtW4v9XoGEJCrGpBMeGEJhy9SOoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexusGhJkRWtN6P95bQR4ryf7G0OePSWQZwFLSZg50AAdkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93BeMKZ78DgwlmEiSkIQHTCE_mCgoWZUhc8y2h1xL3y74Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93bT8OlLqgTvI7zVDwDxmIaYT78paTdIQ4d376vN_kobkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index8D4l7ZZ92CJnFQeme3Pkw0tKcUahpQFAS7OJnu_yE0oMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addtEfl_45LSpcUFODgQ3zlBUXvaM2rSHpEoNNb9Ppp5cTngMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93CpXajMKmTksjc1mFKhfhUhLNXDZ2ZVPYOibTm4VLytQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsQ_aOkmA2eAzUnyk4_45e_45t_451lwtKQIwSw6KYxw9PTzNuoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eicbp1lJJoF5xhNWqJ6Lf2xk64ok4jF9mVxFiwWNFcDDbEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youuWTQXVXari9LBp_a10iHZlnF8XGVMSVGpDwnSCTrhEAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93DjGQp7FnwUwJ4EmaIyuhewgDhHIMphOMyNunM8XaBeAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_933c3XtWs5HgXjEsvOTC6BChONVz9nQEZJmi8_geZAubgMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step16MNukEPLlWc8UFpbKcKe8z1yMGLGwDGGQMNdG4NdisMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as details78ntaZEoUGH7KnnQR2TMA4x4OV9PwTcQ2Rj6Pj45g_kMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsEv4asInYhdJNySHWJdMCV3E_45GqT4byWwR3I5bjIufUgMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexiANgqL6LjEO3aWrvq1uzMZ88Wt0xkWMDdriRFjDE0wMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupqeGIXSlVJxIgIhWi7KrJuZgvnl9noGG0QTINDLyg4LMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youZr_45ndRDlImeXMZmY9D4ve0I02FV24Vi4hxNYs8DjOO8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_9308iX03fQsV_45nga6O0SopAyBoRlYcgwqIbRkqhzS5eLUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93uvKdWW6X6RHKvD_vTvT8Q_45PMdW3KuYGSq5rnAFWkin8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0nBHUx7WgkxzhaTVa_45jYprs8JAg7Nxww_45tUO3RQaEgskMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customerszLhGYLsmBF_454mdmqVnvQMUKPeiEA47SyQ6SEGdF4WR4Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youY75o4B1qUfRVwmC5__450zdoqfuTxErHFuPc3zD2kS4i8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as details6m2zwAFkYgvZ7JxZyplx0_VNBTKF5r4idpUXMSrD0KIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details0pooATtROMjUwzIwW749_45gSo3sccNEYKfR9CwN0cGVEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93jZzwWcCH5D_45D5ZUSKoPlGsCxe0GtVLjh0JeQLe2OXAUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateLUZi4xd8JswwpQf_45jHJtGT_455MdnwyliCo_452EndVxbaQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93jKxHk7sbwM5p1v0pG1ixX37Vl9IAilaJ2uwF3oPtIloMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as details87E5OwTih6Y3VUlG_XUQydPJnbWneQpxUqBwgC45KGUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step07yn_m0G2i3Q6isKhL1JF6jtPBudZyJ71LPodpS9XsNAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexwk69d77RvvA5UZM9WbJ6ILARToisdMYPTLV8eUfSqXAMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexDlwPh9VxHxwa1YRZpaZNufsHpbhVbCE_PNloMEorGsgMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsiWRE3uP43afkVW11GwZavbgkf5_45l7yCDqreI9GYCwmsMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsGN4ApCIdeymy50BhbKNPtC88RMvcQPw64wf68VFo55YMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91location_93pcuOp2dvsQYa885ic90N1XTc__45eSPkoqHf8MOkKPBU8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/energy/compare-by-location/[location].vue?macro=true";
import { default as indexmAeQocvWL_45TYdg6nlLus6oMa91_45bqoo_45_45B8RwSr4y24Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details_vBRu5eJY_45Z3CIGWIUOmBUoNH00E1I46ULY4L7hH9dYMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexlDJYhDaDaZ6w8o2E_45_b6jGY02KZ_45Dw72Kt9sXeqTENMMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successUCLCTfGbVqwUvm3AnipPXp6azdw7M0iUBjNH0aG5ds8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offergTw3qIoxGh9Vv8FqB3WbQUNC3IaiShX_45dUeKL0BFACwMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45details1mvYRcarbq2aJLvgw_Hu49rabBLjwagRlyWKwLhy6MIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as _91step_93EUzCoYhdDDQfcUDylCnkFz4h6zItHo9GEG_45y8N_45q44oMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexORXuhr8899p3vtZzbueTk_0yUX4hfJ37Ku7sL8DjxQkMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93qk3US7sBUR3HPldPYbNdwohVzeLPajICubryv4Jr27EMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexUWSttJgu0U58MSMFUFnDAkJ1S9FwVWmA7_45Wqpr_sQ6IMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_9366Qk555r7hT4oIApj2XoaibUejsDg97Yg0qFEGTvfZ4Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexqzGR8dc_ookfTpjtaGHqVzAWhGpMAY8MDjQQ31A6n8gMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexXCxNKqlnjq0TT3_gvAq4sB_452tzMZ_458emhXpjGQ7f1zYMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsHFoaZ4JMExV5I0c24YpTLfEiJyrfVPe1OqiGUwjsnBIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredW_45T5zTeMqMw9RUyZL2JL_AeK8VLh2dbbuDZ8n2zldF0Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexnwl_s54IEA7kUVbUf129h6VmoiOr5PIUq0o_qo7LRmcMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45you5vLtYQ3t4NJhJr44SsdQL3m_45NOT1mSa_ux4e5HlPEcQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsN6XDqqD1ODosO3EMGLWGgGAunmovsaptDDUx6r4jIW8Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationYx1rtZwYfG6Ja_WmKEupt_GLtSTYR_45B45v385zJYcSIMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionY0VXy_455hiezmEPjQ5TWT2AJ5cTGZy9IDAsUJG49dy54Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsYEcf0By0oDBABYdEEE06pg_DV4K5RY5_ncUtAqlaAscMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationkuxs8_45vYZXz5RsLCWqWGEcXnuYWH_45qAzsnuQCwm_45zSoMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93t53dqzxJ0nsAjQdtfDz_hyv8Dbo5CBpbkxbNfJGV278Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93z8_8BgRI8_cE9u86z74kFfKg604hUYkV_45oc01ahIeTgMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93PaCzvCrstbKAb_9McQADtMLugMmHsawFtqHr0ue9JTsMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93pxvCRA_mEIAuj3hTYzdlWV9NMCVExnGUNOo11hsVSEQMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index5hUGKOmncyOMF1WIX5n8aYsuPC8re1rUVfdpC_3zi3kMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailswsJeii8YKGhd3fO6rsxJs11b8PwYWg01IexCWUygvxgMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93rX7K7s1TH0OxEG_dFTlZT_45VLnF4a0399t0rIKuiPgxUMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_9351VE3y4uUjqByuJVwK0cj2ufrLbdd_45B49isucwDv3R4Meta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexkyBL5nEXK8TloQ4wDoal3rOJo_45MtgmsGyFuuDnhvQXEMeta } from "/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginAoVmwZJYnu0_45oJ9IuEFzk1PNyg8W17mynjFetG_45YfsQMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerzqTUtla5S2a6ckWIk8URK7F3sImDqK8EEG1eCUNMlgEMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password87yJhWjA4n0EBTewRWiGuP4ZwfT68JUhDkprXNdSApsMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexgLYBx_9ALvcway7EDiqRa0PAhwZ9XzWzlr6fMhuZGfsMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordso4pJlgYsdnIfEM58FmmVw5OG1_45krsjpkvaxaQ90o6MMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordW4Kdu2sG1sSSokSJBJIjIAEwcLbzVp6pHgSC5fymNLUMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93i3H85dutAfpDd4TAMI1SZBOtBZxpYhaFQ93oaW46_DAMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexKfbnWZzsAvlRtqxbvxLH5_4564ZKRdhz4_xVp_9rrSslEMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexMrKxtYoXyErFQ49oJzJDs5V6kw2q3lDjjwx_45qzb96lkMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index5NK4Gjtzhz1xswuukEh7iXZUiRtDKZc3q74cDZpDLzwMeta?.props ?? {"verificationNeeded":true},
    meta: index5NK4Gjtzhz1xswuukEh7iXZUiRtDKZc3q74cDZpDLzwMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings8yJYijZ6ehM9va5opDHdnp8pUCE0JH_455oMe66RJ8OgAMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexBVFOdcwG7SsHKFAK314ZlfbvgAYAbigLU78ri4NE_u8Meta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackSdCx1ypM1v4zQLZkv4Wlm23yjhaCGGozvEFbU1UIRiQMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateLUZi4xd8JswwpQf_45jHJtGT_455MdnwyliCo_452EndVxbaQMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsiWRE3uP43afkVW11GwZavbgkf5_45l7yCDqreI9GYCwmsMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "energy-compare-by-location-location",
    path: "/energy/compare-by-location/:location()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/energy/compare-by-location/[location].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offergTw3qIoxGh9Vv8FqB3WbQUNC3IaiShX_45dUeKL0BFACwMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45details1mvYRcarbq2aJLvgw_Hu49rabBLjwagRlyWKwLhy6MIMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93EUzCoYhdDDQfcUDylCnkFz4h6zItHo9GEG_45y8N_45q44oMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionY0VXy_455hiezmEPjQ5TWT2AJ5cTGZy9IDAsUJG49dy54Meta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93t53dqzxJ0nsAjQdtfDz_hyv8Dbo5CBpbkxbNfJGV278Meta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93z8_8BgRI8_cE9u86z74kFfKg604hUYkV_45oc01ahIeTgMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93pxvCRA_mEIAuj3hTYzdlWV9NMCVExnGUNOo11hsVSEQMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailswsJeii8YKGhd3fO6rsxJs11b8PwYWg01IexCWUygvxgMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93rX7K7s1TH0OxEG_dFTlZT_45VLnF4a0399t0rIKuiPgxUMeta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_9351VE3y4uUjqByuJVwK0cj2ufrLbdd_45B49isucwDv3R4Meta || {},
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src765829179/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]