export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            default: '#361564',
            'variant-light': '#671CC2',
            'variant-highlight': '#614983',
            'variant-dark': '#1E0C39',
            'on-default': '#FFFFFF',
            'on-light': '#FFFFFF'
          },
          secondary: {
            default: '#671CC2',
            'variant-light': '#361564',
            'variant-highlight': '#614983',
            'variant-dark': '#1E0C39',
            'on-default': '#FFFFFF',
            'on-light': '#FFFFFF'
          },
          surface: {
            default: '#FFFFFF',
            'on-surface': '#1E0C39',
            'on-surface-variant': '#8C8C89',
            container: '#E2E1E0',
            'container-lowest': '#FBF7FF',
            'container-low': '#EFE8F8',
            'container-high': '#E1D2F3'
          },
          inverse: {
            surface: '#1E0C39',
            'surface-light': '#361564',
            'on-surface': '#FFFFFF',
            primary: '#CBFE50',
            'on-primary': '#1E0C39',
            'primary-variant': '#E2FFA4',
            'primary-variant-light': '#F0FFD1',
            'on-primary-variant': '#1E0C39',
            'container-lowest': '#614983'
          },
          outline: {
            default: '#C5C5C2',
            variant: '#E2E1E0',
            'variant-dark': '#1E0C39',
            'variant-light': '#FFFFFF'
          },
          states: {
            info: {
              default: '#0057AC',
              variant: '#E5ECFB',
              container: '#B8CBF4',
              'variant-dark': '#003E7A',
              'variant-darkest': '#002447',
              'on-default': '#FFFFFF',
              'on-variant': '#003E7A',
              'on-variant-light': '#FFFFFF'
            },
            error: {
              default: '#A3000B',
              variant: '#FCE8EC',
              'on-default': '#FFFFFF',
              'on-variant': '#700007'
            },
            warning: {
              default: '#CC7700',
              variant: '#FFFCE5',
              'on-default': '#FFFFFF',
              'on-variant': '#663B00'
            },
            success: {
              default: '#0D7A12',
              variant: '#E5FAE6',
              'on-default': '#FFFFFF',
              'on-variant': '#084A0B'
            },
            disabled: {
              default: '#F1F0F0',
              'on-default': '#8C8C89'
            }
          }
        },
        base: {
          lavender: {
            lavender: '#671CC2',
            900: '#7733C8',
            800: '#8549CD',
            700: '#9561D4',
            600: '#A477DA',
            500: '#B38DE0',
            400: '#C2A4E6',
            300: '#D1BAEC',
            200: '#E1D2F3',
            100: '#EFE8F8',
            50: '#FBF7FF'
          },
          neutral: {
            neutral: '#191A19',
            900: '#353735',
            800: '#525351',
            700: '#8C8C89',
            600: '#A9A9A6',
            500: '#C5C5C2',
            400: '#D4D3D1',
            300: '#E2E1E0',
            200: '#F1F0F0',
            100: '#FFFFFF'
          },
          purple: {
            'deep-purple': '#1E0C39',
            purple: '#361564',
            900: '#4D3274',
            800: '#614983',
            700: '#745F93',
            600: '#8876A2',
            500: '#9C8DB1',
            400: '#B0A4C1',
            300: '#C4BBD0',
            200: '#D7D1E0',
            100: '#EBE8EF'
          },
          lime: {
            lime: '#CBFE50',
            900: '#D3FF76',
            800: '#D8FF85',
            700: '#DDFF95',
            600: '#E2FFA4',
            500: '#E7FFB3',
            400: '#EBFFC2',
            300: '#F0FFD1',
            200: '#F5FFE1',
            100: '#FAFFF0'
          },
          mint: {
            'deep-mint': '#59FFD7',
            mint: '#B4FFED',
            900: '#7AFFDF',
            800: '#9BFFE7',
            700: '#ACFFEB',
            500: '#CDFFF3',
            400: '#DEFFF7',
            300: '#E6FFF9',
            200: '#EEFFFB',
            100: '#F7FFFD'
          },
          rose: {
            'deep-rose': '#FF83E7',
            rose: '#FFC9FA',
            900: '#FF9CEC',
            800: '#FFB5F1',
            700: '#FFC1F3',
            500: '#FFDaf8',
            400: '#FFE6FA',
            300: '#FFECFB',
            200: '#FFF3FD',
            100: '#FFF9FE'
          },
          oat: {
            'deep-oat': '#FFD3BF',
            oat: '#FFF6F2',
            900: '#FFDCCC',
            800: '#FFE5D9',
            700: '#FFE9DF',
            600: '#FFEDF5',
            500: '#FFF2EC',
            300: '#FFF8F5',
            200: '#FFFBF9',
            100: '#FFFDFC'
          }
        }
      }
    }
  }
}
