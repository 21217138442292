/*
 * This plugin captures performance metrics and sends them to Google Tag Manager (GTM) via the dataLayer
 * It listens for the 'page:finish' event and checks if the current route matches specific patterns
 * If it does, it adds an event listener to the window's load event to capture performance metrics and sends them to the dataLayer
 * The metrics include load time, DOM content loaded time, first paint time, and first contentful paint time
 * The performance metrics are captured using the Performance API
 */
const pagePatterns = [
  /^\/login\/?$/,
  /^\/register\/?$/,
  /^\/dashboard(\/.*)?\/?$/
]
  
function shouldCaptureForPage (path) {
  return pagePatterns.some(pattern => pattern.test(path))
}

function getDisplayMode () {
  if (typeof window !== 'undefined') {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return 'standalone'
    }
    return 'browser'
  }
  return 'unknown'
}

function startPerformanceObservers (metrics) {
  const lcpObserver = new PerformanceObserver(entryList => {
    for (const entry of entryList.getEntries()) {
      metrics.largestContentfulPaint = entry.startTime
    }
  })
  lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true })
  
  let cumulativeLayoutShiftScore = 0
  const clsObserver = new PerformanceObserver(entryList => {
    for (const entry of entryList.getEntries()) {
      if (!entry.hadRecentInput) {
        cumulativeLayoutShiftScore += entry.value
      }
    }
    metrics.cumulativeLayoutShift = cumulativeLayoutShiftScore
  })
  clsObserver.observe({ type: 'layout-shift', buffered: true })

  // Observer for paint timings
  const paintObserver = new PerformanceObserver(entryList => {
    entryList.getEntries().forEach(entry => {
      if (entry.name === 'first-paint') {
        metrics.firstPaint = entry.startTime
      }
      if (entry.name === 'first-contentful-paint') {
        metrics.firstContentfulPaint = entry.startTime
      }
    })
  })
  paintObserver.observe({ type: 'paint', buffered: true })
}

const captureInitialLoadMetrics = pageName => {
  if (performance && typeof window !== 'undefined') {
    const navigationTiming = performance.getEntriesByType('navigation')[0]
    const displayMode = getDisplayMode()
  
    const metrics = {
      pageName: pageName,
      displayMode: displayMode,
      loadTime: navigationTiming?.loadEventEnd - navigationTiming?.startTime || null,
      domContentLoaded: navigationTiming?.domContentLoadedEventEnd - navigationTiming?.startTime || null,
      firstPaint: 'Not captured',
      firstContentfulPaint: 'Not captured',
      largestContentfulPaint: 'Not captured',
      cumulativeLayoutShift: 'Not captured'
    }

    // Observers for additional metrics
    startPerformanceObservers(metrics)
  
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'initialLoadMetrics',
      metrics
    })
  
    console.log('%cPerformance:Initial load metrics sent to dataLayer:', 'color: orange; font-weight: bold;', metrics)
  }
}
  
const capturePageTransitionMetrics = pageName => {
  if (performance && typeof window !== 'undefined') {
    const transitionStart = performance.now()
    const displayMode = getDisplayMode()
    // Using nextTick or requestAnimationFrame to allow rendering or actions to settle
    nextTick(() => { // Assuming any needed DOM updates are complete
      const transitionDuration = performance.now() - transitionStart // Duration is in milliseconds
  
      const metrics = {
        pageName: pageName,
        displayMode: displayMode,
        transitionDuration: transitionDuration
      }

      // Observers for additional metrics
      // startPerformanceObservers(metrics)
  
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'pageTransitionMetrics',
        metrics
      })
  
      console.log('%cPerformance:Page transition metrics sent to dataLayer:', 'color: orange; font-weight: bold;', metrics)
    })
  }
}

export default defineNuxtPlugin(nuxtApp => {
  // Track if the initial load has been captured
  let initialLoadCaptured = false

  // Expose the function globally using Nuxt provide
  nuxtApp.provide('capturePerformanceMetrics', captureInitialLoadMetrics)
  nuxtApp.provide('capturePageTransitionMetrics', capturePageTransitionMetrics)

  // Capture metrics right after the app is mounted
  nuxtApp.hook('app:mounted', () => {
    const router = useRouter()
    const currentRoute = router.currentRoute.value
    const currentPath = currentRoute.path

    if (!initialLoadCaptured && shouldCaptureForPage(currentPath)) {
      const pageName = currentRoute?.name || currentRoute?.path
      captureInitialLoadMetrics(pageName)
    }
  })

  nuxtApp.hook('page:finish', async () => {
    const router = useRouter()
    const currentRoute = router.currentRoute.value
    const currentPath = currentRoute?.path

    if (initialLoadCaptured && shouldCaptureForPage(currentPath)) {
      const pageName = currentRoute?.name || currentRoute?.path // Fallback to path if name is unset
      await nextTick() // Ensure that DOM updates are settled
      capturePageTransitionMetrics(pageName)
    }

    initialLoadCaptured = true // Ensure initial load metrics are only captured once
  })
})