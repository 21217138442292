export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            default: '#0AC16A',
            'variant-light': '#193560',
            'variant-highlight': '#180C33',
            'variant-dark': '#0AC16A',
            'on-default': '#193560',
            'on-light': '#FFFFFF'
          },
          secondary: {
            default: '#193560',
            'variant-light': '#193560',
            'variant-highlight': '#180C33',
            'variant-dark': '#193560',
            'on-default': '#193560',
            'on-light': '#FFFFFF'
          },          
          surface: {
            default: '#FFFFFF',
            'on-surface': '#193560',
            'on-surface-variant': '#666766',
            'container': '#F0F0F0',
            'container-lowest': '#F9F9F9',
            'container-low': '#F4F5F7',
            'container-high': '#E8EBEF'
          },
          inverse: {
            'surface': '#0B1D39',
            'surface-light': '#193560',
            'on-surface': '#FFFFFF',
            primary: '#0AC16A',
            'on-primary': '#193560',
            'primary-variant': '#0AC16A',
            'primary-variant-light': '#0AC16A',
            'on-primary-variant': '#193560',
            'container-lowest': '#304970'
          },
          outline: {
            default: '#D2D2D2',
            'variant': '#D2D2D2',
            'variant-dark': '#D2D2D2',
            'variant-light': '#FFFFFF'
          },
          states: {
            info: {
              default: '#74CEFF',
              variant: '#EFF9FF',
              container: '#EFF9FF',
              'variant-dark': '#EFF9FF',
              'variant-darkest': '#EFF9FF',
              'on-default': '#FFFFFF',
              'on-variant': '#003E7A',
              'on-variant-light': '#74CEFF'
            },
            error: {
              default: '#C50E0E',
              variant: '#FFE1E1',
              'on-default': '#FFFFFF',
              'on-variant': '#C50E0E'
            },
            warning: {
              default: '#FFE847',
              variant: '#FFFCE7',
              'on-default': '#FFFFFF',
              'on-variant': '#FFE847'
            },
            success: {
              default: '#0AC16A',
              variant: '#F2FFF9',
              'on-default': '#FFFFFF',
              'on-variant': '#0AC16A'
            },
            disabled: {
              default: '#F0F0F0',
              'on-default': '#AAAAAA'
            }
          }
        },
        base: {
          lavender: {
            lavender: '#671CC2',
            900: '#7733C8',
            800: '#8549CD',
            700: '#9561D4',
            600: '#A477DA',
            500: '#B38DE0',
            400: '#C2A4E6',
            300: '#D1BAEC',
            200: '#E1D2F3',
            100: '#EFE8F8',
            50: '#FBF7FF'
          },
          neutral: {
            neutral: '#191A19',
            900: '#353735',
            800: '#525351',
            700: '#8C8C89',
            600: '#A9A9A6',
            500: '#C5C5C2',
            400: '#D4D3D1',
            300: '#E2E1E0',
            200: '#F1F0F0',
            100: '#FFFFFF'
          },
          purple: {
            'deep-purple': '#1E0C39',
            purple: '#361564',
            900: '#4D3274',
            800: '#614983',
            700: '#745F93',
            600: '#8876A2',
            500: '#9C8DB1',
            400: '#B0A4C1',
            300: '#C4BBD0',
            200: '#D7D1E0',
            100: '#EBE8EF'
          },
          lime: {
            lime: '#CBFE50',
            900: '#D3FF76',
            800: '#D8FF85',
            700: '#DDFF95',
            600: '#E2FFA4',
            500: '#E7FFB3',
            400: '#EBFFC2',
            300: '#F0FFD1',
            200: '#F5FFE1',
            100: '#FAFFF0'
          },
          mint: {
            'deep-mint': '#59FFD7',
            mint: '#B4FFED',
            900: '#7AFFDF',
            800: '#9BFFE7',
            700: '#ACFFEB',
            500: '#CDFFF3',
            400: '#DEFFF7',
            300: '#E6FFF9',
            200: '#EEFFFB',
            100: '#F7FFFD'
          },
          rose: {
            'deep-rose': '#FF83E7',
            rose: '#FFC9FA',
            900: '#FF9CEC',
            800: '#FFB5F1',
            700: '#FFC1F3',
            500: '#FFDaf8',
            400: '#FFE6FA',
            300: '#FFECFB',
            200: '#FFF3FD',
            100: '#FFF9FE'
          },
          oat: {
            'deep-oat': '#FFD3BF',
            oat: '#FFF6F2',
            900: '#FFDCCC',
            800: '#FFE5D9',
            700: '#FFE9DF',
            600: '#FFEDF5',
            500: '#FFF2EC',
            300: '#FFF8F5',
            200: '#FFFBF9',
            100: '#FFFDFC'
          }
        }
      }
    }
  }
}
